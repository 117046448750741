#container {
  margin: 24px;
}

#container h2 {
  margin-bottom: 10px;
}

.drag-handle {
  cursor: move !important;
}
