/**
  primary: 'rgba(30, 39, 70, 1)', // #1E2746
  secondary: 'rgba(101, 194, 196, 1)', // #65C2C4
  brand: 'rgba(30, 39, 70, 1)',
 */

@font-face {
  font-family: "Regular"; /*Can be any text*/
  src: local("Amble-Regular"),
    url("./assets/fonts/Amble-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Bold"; /*Can be any text*/
  src: local("Amble-Bold"),
    url("./assets/fonts/Amble-Bold.ttf") format("truetype");
}

/**
 * BUTTON STYLE
 */
.ant-btn-primary {
  background-color: #65c2c4 !important;
  border-color: #65c2c4;
}

/**
 * PAGINATION STYLE
 */
.ant-pagination-item-active {
  border-color: #65c2c4 !important;
}

li.ant-pagination-item-active a {
  color: #65c2c4 !important;
}

/**
 * MENU STYLE
 */
.ant-menu-item-selected {
  background-color: #65c2c4 !important;
}

.ant-menu-item-selected span {
  color: white !important;
}

.ant-menu-item-active {
  color: #65c2c4 !important;
}
